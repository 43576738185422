<template>
<div>
    <NavComp />
    <div class="container">
        <section class="header">
            <h1 class="tut-heading">{{ $t("tutorial.heading.value") }}</h1>
            <p class="intro">
                {{ $t("tutorial.subheading.text") }}
            </p>
        </section>
        <section>
            <div class="card boxed">
                <div class="element">
                    <h2 class="heading">{{ $t("tutorial.home.heading")}}</h2>
                    <p class="text"> 
                        {{ $t("tutorial.home.text")}}
                    </p>
                </div>
                <img src="../../public/img/sg/tutorial/home_1.png" alt="Homescreen" class="element full">
            </div>

            <div class="card second">
                <div class="boxed">
                    <img src="../../public/img/sg/tutorial/garden_menu_2.png" alt="" class="element full">
                    <div class="">
                        <h2 class="heading">{{ $t("tutorial.newGarden.heading")}}</h2>
                        <p class="text">
                            {{ $t("tutorial.newGarden.text1")}}
                            <b>{{ $t("tutorial.newGarden.name")}}</b>
                            {{ $t("tutorial.newGarden.text2")}}
                            {{ $t("tutorial.newGarden.text3")}}
                            <span>
                                {{ $t("tutorial.newGarden.text4")}}
                            </span>
                        </p>
                    </div>
                </div>
            </div>

            <div class="card boxed">
                <div class="element">
                    <h2 class="heading">{{ $t("tutorial.home_creation.heading")}}</h2>
                    <p class="text"> 
                        {{ $t("tutorial.home_creation.text")}}
                    </p>
                </div>
                <img src="../../public/img/sg/tutorial/home_with_garden_and_fertilizer_3.png" alt="butterfly-and-flower" class="element">
            </div>

            <div class="card boxed">
                <div class="element">
                    <h2 class="heading">{{ $t("tutorial.garden.heading")}}</h2>
                    <p class="text"> 
                        {{ $t("tutorial.garden.text")}}
                    </p>
                </div>
                <img src="../../public/img/sg/tutorial/garden_4.png" alt="butterfly-and-flower" class="element">
            </div>

            <div class="card boxed">
                <div class="element">
                    <h2 class="heading">{{ $t("tutorial.garden_with_types.heading")}}</h2>
                    <p class="text"> 
                        {{ $t("tutorial.garden_with_types.text")}}
                    </p>
                </div>
                <img src="../../public/img/sg/tutorial/garden_with_types_5.png" alt="butterfly-and-flower" class="element">
            </div>

            <div class="card">
                <div class="boxed">
                    <img src="../../public/img/sg/tutorial/garden_menu_6.png" alt="" class="element">
                    <div class="">
                        <h2 class="heading">{{ $t("tutorial.garden_menu.heading")}}</h2>
                        <p class="text">{{ $t("tutorial.garden_menu.text") }}</p>
                    </div>
                </div>
            </div>

            <div class="card boxed">
                <div class="element">
                    <h2 class="heading">{{ $t("tutorial.finished_garden.heading")}}</h2>
                    <p class="text"> 
                        {{ $t("tutorial.finished_garden.text")}}
                    </p>
                </div>
                <img src="../../public/img/sg/tutorial/garden_with_herbs_7.png" alt="butterfly-and-flower" class="element">
            </div>

            <div class="card boxed">
                <div class="element">
                    <h2 class="heading">{{ $t("tutorial.ending.heading")}}</h2>
                    <p class="text"> 
                        {{ $t("tutorial.ending.text")}}
                        {{ $t("tutorial.ending.text2")}}
                    </p>
                </div>
                <img src="../../public/img/sg/tutorial/end_season_8.png" alt="butterfly-and-flower" class="element">
            </div>
            <!--
            <div class="card second">
                <h2 class="heading">{{ $t("contact.contactCard.heading")}}</h2>
                <div class="boxed-form">
                    <img src="../assets/contact_pictures/ideas.jpg" alt="" class="element">
                    <div class="">
                        <p class="text">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dignissimos cum, animi placeat dolores ipsam aliquam accusantium praesentium. Consequuntur voluptatem incidunt quod voluptas reiciendis provident facilis nam eligendi, aut quae veritatis eius recusandae ex velit ad qui magnam eveniet? Amet, nobis aliquid? Nam nobis molestias incidunt sit soluta cupiditate eveniet, consequatur laboriosam autem! Qui animi doloremque magni magnam, molestiae repellat autem fugit rem incidunt rerum dolore soluta corrupti, laborum, sapiente id tempore! Eos doloremque quidem velit quam sint qui voluptates dolorum dolore modi, officiis esse, rerum omnis totam cupiditate delectus neque reiciendis quis cumque, perspiciatis culpa dolor quod earum labore? Ipsam?</p>
                    </div>
                </div>
            </div> -->
        </section>
        <div class="foot"></div>
    </div>
</div>
</template>

<script>
import NavComp from "@/components/NavComp.vue";

export default {
    name: "Tutorial",
    components: {
        NavComp,
    }
}
</script>

<style scoped>
img {
    border-radius: 25px;
    padding: 5px 0;
}

.header {
    text-align: center;
}

.boxed {
    text-align: center;
}

.intro {
    text-align: center;
    font-weight: 400;
    font-size: 1.1rem;
    margin-bottom: 16px;
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
}

.element {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    align-items: center;
}

.full {
    margin: 0 auto;
}


h1.tut-heading {
    color: #107869;
    font-size: 2.2rem;
    margin-top: 8px;
    font-weight: 600;
    margin-bottom: 50px;
}

h2.heading {
    font-size: 1.8rem;
    font-weight: 500;
    margin-left: 0.1em;
    margin-bottom: 1em;
    text-align: center;
    margin-top: 1em;
}

.text {
    margin: 0 0.3em;
    text-align: left;
}

.card {
    max-width: 1200px;
    padding: 5px;
    margin: 20px 10px;
}


.save-button {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    margin-bottom: 10px;
}

i{
    margin-right: 5px;
}

.foot {
    text-align: center;
    padding-bottom: 20px;
}

@media screen and (min-width: 600px) {
    .boxed {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        text-align: center;
    }
    .img {
        max-width: 600px;
        max-height: 600px;
    }
}
</style>